import { template as template_fad7f072aa9b4a6186bfbcf5f4372ecf } from "@ember/template-compiler";
const SidebarSectionMessage = template_fad7f072aa9b4a6186bfbcf5f4372ecf(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
