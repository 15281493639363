import { template as template_347135c3439e42cf99f69f64bbe42d05 } from "@ember/template-compiler";
const FKLabel = template_347135c3439e42cf99f69f64bbe42d05(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
